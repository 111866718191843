<template>
  <div class="user-index">

    <div id="myMsgbBg">
      <van-nav-bar class="head_nav_relative">
        <template #left>
          <van-icon @click="back()"
                    name="arrow-left"
                    size="1.5rem"
                    color="#fff"
                    v-permission="{'UserOrder':'UserOrder'}" />

        </template>
        <template #right>
          <van-icon name="chat-o"
                    dot
                    size="1.5rem"
                    v-if="isNewMsgTag"
                    v-permission="{'ChatUserList':'ChatUserList'}"
                    color="#fff"
                    @click="news({ name: 'ChatUserList'})" />
          <van-icon name="chat-o"
                    size="1.5rem"
                    v-if="message"
                    v-permission="{'ChatUserList':'ChatUserList'}"
                    color="#fff"
                    @click="news({ name: 'ChatUserList'})" />
        </template>
      </van-nav-bar>
      <van-pull-refresh v-model="refreshing"
                        @refresh="onRefresh"
                        success-text="刷新成功"
                        class="refreshT">
        <div id="preMyMsg">
          <div class="hokey">&nbsp;</div>
          <div id="headMsg"
               @click="go({ name: 'Info' })"
               v-permission="{'Info':'Info'}">
            <div class="myMsgImg">
              <img :src="portraitUrl"
                   class="imgere">
            </div>
            <div class="myMsgText">
              <div>
                <span>{{nickName }}</span>
              </div>
              <div class="yEAndPF"></div>
            </div>
          </div>
        </div>
        <div class="myMsgListBg">
          <div class="myMsgTab">
            <div class="tab_div"
                 @click="balance"
                 v-permission="{'BalanceRecord':'BalanceRecord'}">
              <p class="yunag">{{Balance }}</p>
              <span class="yuee">余额(元)</span>
            </div>
            <div class="tab_div"
                 @click="go({ name: 'RedPack' })"
                 v-permission="{'RedPack':'RedPack'}">
              <p class="yunag">{{redPack}}</p>
              <span class="yuee">红包(元)</span>
            </div>
            <div class="tab_div"
                 @click="go({ name: 'Coupon' })"
                 v-permission="{'Coupon':'Coupon'}">
              <p class="pfeng">{{coupon}}</p>
              <span class="yuee">优惠券(张)</span>
            </div>
            <div class="tab_div"
                 :class="{ active:isActiveSet }">
              <p class="pfeng">{{DriverScore }}</p>
              <span>评分</span>
            </div>
          </div>

          <div class="msgList">
            <van-cell-group>
              <van-cell title="我的订单"
                        is-link
                        @click="go({ name: 'Order'})"
                        v-permission="{'Order':'Order'}" />
              <van-cell title="红包"
                        is-link
                        @click="go({ name: 'RedPack' })"
                        v-permission="{'RedPack':'RedPack'}" />
              <van-cell title="优惠券"
                        is-link
                        @click="go({ name: 'Coupon' })"
                        v-permission="{'Coupon':'Coupon'}" />
              <van-cell v-if="isShowDriverApply"
                        title="司机招募"
                        is-link
                        v-permission="{'Apply':'Apply'}"
                        @click="go({ name: 'Apply' })" />
              <van-cell v-if="isShowDriver"
                        title="司机信息"
                        is-link
                        v-permission="{'DriverInfo':'DriverInfo'}"
                        @click="go({ name: 'DriverInfo' })" />
            </van-cell-group>
          </div>
          <div class="msgList">
            <van-cell-group>
              <van-cell title="系统帮助"
                        is-link
                        @click="go({ name: 'ArticleType' })"
                        v-permission="{'ArticleType':'ArticleType'}" />
              <van-cell title="建议反馈"
                        is-link
                        @click="go({ name: 'Feedback' })"
                        v-permission="{'Feedback':'Feedback'}" />
              <van-cell title="关于我们"
                        is-link
                        @click="ToAboutUs()" />
            </van-cell-group>
          </div>
          <div class="msgList">
            <van-cell-group>
              <van-cell title="退出登录"
                        is-link
                        @click="LogOutS"
                        class="chuchu" />
            </van-cell-group>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { reactive, onMounted, ref, toRefs, watch, watchEffect } from "vue";
import { Logout } from "@/serviceApi/Account";
import { delCookie } from "@/commonContent/js/comm";
import { ChatUserList } from "@/serviceApi/Chat.js";
import { GetTokeUserInfo } from "@/utils/validate .js";
import { IsNullOrEmpty, BackOff } from "@/commonContent/js/comm.js";
import { Toast } from "vant";
import { setCookie } from "@/commonContent/js/Cookie";
import { returnFloates } from "@/commonContent/js/comm";
import { useStore } from "vuex";
import { returnFloat } from "@/commonContent/js/comm";
import { connection } from "@/utils/signalR.js";
export default {
  setup () {
    const refreshing = ref(false);
    const state = reactive({
      isShow: true,
      isActiveMsg: true,
      isActiveSet: false,
      redPack: "",
      Balance: "",
      DriverScore: "",
      userInfo: {},
      coupon: "",
      isShowDriverApply: false,
      isShowDriver: false,
      userScore: "",
      nickName: "",
      driverScore: "",
      portraitUrl: "",
      message: true,
      timet: "",
      isNewMsgTag: false,
    });
    const router = useRouter();
    const store = useStore();
    /*   const getRandomNum = function (min, max, countNum) {
        let arr = [];
        // 在此处补全代码
        function produceNum () {
          let num = Math.floor((Math.random() * (max - min)) + min)
          if (arr.indexOf(num) == -1) { //判断不存在就push
            arr.push(num)
          } else {
            produceNum() //存在了重新调用
          };
          if (arr.length < countNum) { //判断数组长度是否小于所需长度
            produceNum();
          }
        }
        produceNum()
        return arr;
      }; */
    onMounted(() => {
      onLoad();
      connection.on("signalRMsgMethod", function (chatContent) {
        state.message = false;
        state.isNewMsgTag = true;
      });
      if (sessionStorage.getItem("sysMsg") != null) {
        state.message = false;
        state.isNewMsgTag = true;
      }
    });
    const onLoad = () => {
      state.Balance = store.state.data.balance;
      if (returnFloat(store.state.data.redPack) == "NaN.00") {
        state.redPack = "0";
      } else {
        state.redPack = returnFloat(store.state.data.redPack);
      }
      state.coupon = store.state.data.coupon;
      store.dispatch("GetCurrUserFullFund", {
        getCouponList: false,
        callback: function (data) {
          state.Balance = data.balance;
          state.redPack = returnFloat(data.redPack);
          state.coupon = data.coupon;
        },
      });
      state.nickName = store.state.personalInformation.nickName;
      state.userScore = store.state.personalInformation.userScore;
      state.driverScore = store.state.personalInformation.driverScore;
      state.portraitUrl = store.state.personalInformation.portraitUrl;
      if (!IsNullOrEmpty(GetTokeUserInfo().did)) {
        state.isShowDriverApply = false;
        state.isShowDriver = true;
        if (
          returnFloat(store.state.personalInformation.driverScore) == "NaN.00"
        ) {
          state.DriverScore = "0";
        } else {
          state.DriverScore = returnFloates(
            store.state.personalInformation.driverScore
          );
        }
      } else {
        state.isShowDriverApply = true;
        state.isShowDriver = false;
        if (
          returnFloat(store.state.personalInformation.userScore) == "NaN.00"
        ) {
          state.DriverScore = "0";
        } else {
          state.DriverScore = returnFloates(
            store.state.personalInformation.userScore
          );
        }
      }
      store.dispatch("GetCurrUserInfo", {
        callback: function (data) {
          state.nickName = data.nickName;
          state.userScore = data.userScore;
          state.driverScore = data.driverScore;
          state.portraitUrl = data.portraitUrl;
          if (data.isDriver) {
            state.isShowDriverApply = false;
            state.isShowDriver = true;
            state.DriverScore = returnFloates(data.driverScore);
          } else {
            state.isShowDriverApply = true;
            state.isShowDriver = false;
            state.DriverScore = returnFloates(data.userScore);
          }
        },
      });
      ChatUserList().then((res) => {
        if (res.code == 1) {
          for (let index = 0; index < res.data.list.length; index++) {
            if (res.data.list[index].isNewMsgTag) {
              state.message = false;
              state.isNewMsgTag = true;
              continue;
            }
          }
        } else {
          Toast.fail(res.msg);
        }
      });
    };
    const go = (val) => {
      router.push(val);
    };
    const LogOutS = () => {
      Toast.loading({
        message: "正在退出...",
        duration: 0,
      });
      Logout(window.location.href)
        .then((res) => {
          if (res.code == 1) {
            setCookie("token", "");
            delCookie("ASP.NET_SessionId");
            delCookie("SessionId");
            sessionStorage.clear();
            window.location.href = res.data;
          } else {
            setCookie("token", "");
            delCookie("ASP.NET_SessionId");
            delCookie("SessionId");
            window.location.href = "" + process.env.VUE_APP_API_UA + "/account/login?returnurl=" + encodeURIComponent(window.location.href);
          }
        })
        .catch((err) => {
          Toast.fail(err);
        });
    };
    const ShowMsg = (isShow, isActiveMsg, isActiveSet) => {
      state.isShow = isShow;
      state.isActiveMsg = isActiveMsg;
      state.isActiveSet = isActiveSet;
    };
    const ToAboutUs = () => {
      window.location.href = "http://www.eonup.com";
    };

    const back = () => {
      BackOff(router.currentRoute.value);
      router.push({ path: "/Home/UserOrder" });
    };
    const balance = () => {
      router.push({ name: "BalanceRecord" });
    };
    const onRefresh = () => {
      setTimeout(() => {
        refreshing.value = false;
        onLoad();
      }, 1000);
    };
    const news = (val) => {
      state.message = true;
      state.isNewMsgTag = false;
      sessionStorage.removeItem("msgsWitch");
      router.push(val);
    };
    return {
      go,
      onRefresh,
      back,
      LogOutS,
      ...toRefs(state),
      ShowMsg,
      ToAboutUs,
      GetTokeUserInfo,
      balance,
      refreshing,
      onLoad,
      news,
    };
  },
};
</script>
<style lang="less" scoped>
</style>