import { PostApi, PutApi, GetApi } from "../utils/request";
import ApiVersion from "@/Enum/enumeration"

var CURR_VIEW_VERSION = ApiVersion.ApiVersion.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `/M/${CURR_VIEW_VERSION}`;

export const ChatUserList = (params) => {
    //聊天列表查询
    return GetApi({ url: `/api${VIEW_VERSION}/Chat/ChatUserList/GetPageList`, data: params,intercept:true  });
  };

  export const ChatMsgGetPageList = (params) => {
    //聊天列表查询
    return GetApi({ url: `/api${VIEW_VERSION}/Chat/ChatMsg/GetPageList`, data: params, intercept:true });
  };
  export const ChatMsgGet = (params) => {
    //查询聊天室
    return GetApi({ url: `/api${VIEW_VERSION}/Chat/ChatMsg/Get`, data: params, });
  };
  export const GetChatSysMsgPage = (params) => {
     //查询通知
    return GetApi({ url: `/api${VIEW_VERSION}/Chat/ChatSysMsg/GetPage`, data: params,intercept:true  });
  };
  export const UpdateStatus = (params) => {
    //修改通知未读状态
   return PutApi({ url: `/api${VIEW_VERSION}/Chat/ChatSysMsg/UpdateStatus`, data: params });
 };
 export const UpdateAllStatus = (params) => {
  //修改通知全部未读状态
 return PutApi({ url: `/api${VIEW_VERSION}/Chat/ChatSysMsg/UpdateAllStatus`, data: params });
};
 export const GetChatSysMsgInfo = (params) => {
  //查询通知详情
 return GetApi({ url: `/api${VIEW_VERSION}/Chat/ChatSysMsg/Get`, data: params });
};
